import { Controller } from "stimulus"

export default class extends Controller {

  static targets = [ "subcategoryToggle", "sidebar" ]

  initialize() {

  }

  connect() {

  }

  toggleSubcategories() {
    let parent = event.target.closest("li");
    if(parent) {
      let submenu = parent.querySelector("ul.subcategory");
      if(submenu) {
        event.target.children[0].classList.toggle('is-active');
        submenu.classList.toggle('is-active');
      }
    }

  }

  toggleCompleteMenu() {
    let sidebar = document.querySelector('#sidebar')
    sidebar.classList.toggle('active');
  }
}
