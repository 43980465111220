import { Controller } from "stimulus"

export default class extends Controller {

  static targets = [ "header", "headerImages", "footer" ]

  initialize() {
    this.country = this.element.getAttribute("data-country");
    if(!this.country) {
      this.country = document.querySelector("meta[name='country']").getAttribute('content');
    }
  }

  connect() {
    let applController = this;
    var observer = new IntersectionObserver(function(entries) {
    	// isIntersecting is true when element and viewport are overlapping
    	// isIntersecting is false when element and viewport don't overlap
    	if(entries[0].isIntersecting === true) {
        applController.offsetHero = window.scrollY;
      }
    }, { threshold: [0] });

    var ctrl = this;
    if(this.hasFooterTarget) {
      observer.observe(this.footerTarget);
    }

    window.onVisible = function(element, callback) {
      new IntersectionObserver((entries, observer) => {
        entries.forEach(entry => {
          if(entry.intersectionRatio > 0) {
            callback(element);
            observer.disconnect();
          }
        });
      }).observe(element);
    }

    document.querySelectorAll(".opacity-0").forEach((fig, i) => {
      onVisible(fig, () => {
        fig.classList.add('fade-in');
        fig.classList.remove('opacity-0');
      });
    });

    if(document.querySelector('#carousel-header')) {
      this.carousel = bulmaCarousel.attach('#carousel-header', {
        slidesToScroll: 1,
        slidesToShow: 1,
        loop: true,
        navigation: false,
        navigationKeys: false,
        autoplay: true,
        autoplaySpeed: 8000,
        effect: 'fade',
        duration: 2000
      });
    }
  }

  onScroll() {
    this.y = window.scrollY/2;
    if(this.hasHeaderTarget) {
      this.headerTarget.style.backgroundPosition = `center ${0-this.y}px`
    }
    if(this.hasFooterTarget) {
      if(this.offsetHero && (this.offsetHero-this.y) < this.footerTarget.clientHeight) {
        this.footerTarget.style.backgroundPosition = `center ${this.offsetHero-this.y}px`
      }
    }
  }

  search() {
    let div = document.createElement("div");
    div.classList.add('modal', 'p-3');
    div.setAttribute("id", "search");

    div.innerHTML = `
      <div class="modal-background" data-controller="application" data-action="click->application#hideSearch keydown.esc->application#hideSearch"></div>
      <div class="modal-content mx-2">
        <div class="card has-background-whitem-6 p-6">
          <form class="my-auto" action="/${this.country.toLowerCase()}-${I18n.locale}/search" accept-charset="UTF-8" method="post">
            <input type="hidden" name="authenticity_token" value="${Rails.csrfToken()}" autocomplete="off">
            <div class="field search has-addons">
              <p class="control is-flex-grow-1">
                <input class="input is-large is-focused" type="text" id="search" name="search" placeholder="${I18n.t('search.search')}" data-main-target="input_search">
              </p>
              <div class="control">
                <button class="button is-large" type="submit">
                  <i class="fas fa-search"></i>
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    `
    document.querySelector('body').appendChild(div)
    div.classList.add('is-active')
    div.querySelector("input#search").focus()
  }

  hideSearch() {
    let modal = document.querySelector("div.modal#search");
    modal.classList.remove('is-active');
    modal.remove()
  }

  acceptCookies() {
    Rails.ajax({
      url: `/${this.country.toLowerCase()}-${this.locale}/accept-cookies`,
      type: "post",
      data: null,
      contentType: false,
      processData: false,
      success: function(data) {
        window.location.reload()
      },
      error: function(data) {}
    });
  }
}
