import { Controller } from "stimulus"
import PinchZoom from "pinch-zoom-js"
import Gesture from 'tinygesture';

export default class extends Controller {

  static targets = [ "image", "productPicture", "productPictureDescription", "productPictureWrapper", "productPictureThumbnails", "image_left_icon", "image_zoom_icon", "image_right_icon", "inputUuid", "colorVariant", "sizeVariant", "buttonAddToCart", "price", "itemStock", "spanPriceWithVat", "tabs", "tabsContent", "bullets", "productVideoWrapper" ]

  static values = {
    unitSteps: Number
  }

  initialize() {
    this.locale = this.element.getAttribute("data-locale");
    if(!this.locale) {
      this.locale = document.querySelector("meta[name='locale']").getAttribute('content');
    }

    this.currency = document.querySelector("meta[name='currency']").getAttribute('content');
  }

  connect() {
    let li = this.tabsTarget.querySelector("li:not(.is-hidden)");
    if(li) {
      li.classList.add('is-active');
      let tab = this.tabsContentTarget.querySelector(`div#${li.children[0].dataset.target}.tab-content`);
      if(tab) {
        tab.classList.remove('is-hidden');
      }
    }

    setTimeout( () => {
      let div = this.imageTarget.parentNode.parentNode;
      div.setAttribute("data-height", div.offsetHeight);
    }, 500)

    const gesture = new Gesture(this.imageTarget, {
      diagonalSwipes: false
    });
    gesture.on('swiperight', (event) => {
      this.previewImageNavigation("right");
    });
    gesture.on('swipeleft', (event) => {
      this.previewImageNavigation("left");
    });

    if(this.unitStepsValue) {
      let inp = document.querySelector("input#item_quantity");
      if(inp) {
        inp.value = this.unitStepsValue;
        inp.setAttribute("min", this.unitStepsValue);
        inp.setAttribute("steps", this.unitStepsValue);
      }
    }
  }

  imageNavigation() {
    let active = parseInt(this.productPictureThumbnailsTarget.dataset.active);
    let total = parseInt(this.productPictureThumbnailsTarget.dataset.thumbnails);
    let thumbnails = this.productPictureThumbnailsTarget.querySelectorAll('img');
    let keycode = event.keyCode;
    if(event.keyCode) {
      keycode = event.keyCode;
    } else {
      if(event.target.dataset.direction) {
        if(event.target.dataset.direction == 'left') {
          keycode = 37;
        } else {
          keycode = 39;
        }
      }
    }

    switch (keycode) {
      case 27:
        this.productPictureWrapperTarget.classList.add('is-hidden');

        let iframe = this.productPictureWrapperTarget.querySelector("iframe");
        if(iframe) {
          iframe.remove();
        }
        let video = this.productPictureWrapperTarget.querySelector("video");
        if(video) {
          video.remove();
        }

        break;
      case 39:
        //right
        this.pictureNavigation("left")
        break;
      case 37:
        //left
        this.pictureNavigation("right")
        break;
    }
  }

  pictureNavigation(direction) {
    let active = parseInt(this.productPictureThumbnailsTarget.dataset.active);
    let total = parseInt(this.productPictureThumbnailsTarget.dataset.thumbnails);
    let thumbnails = this.productPictureThumbnailsTarget.querySelectorAll('img');
    if(direction == "left") {
      thumbnails[active].classList.remove('active');
      if(active < total-1) {
        active++;
      } else {
        active = 0;
      }
      this.productPictureThumbnailsTarget.setAttribute("data-active", active);
      thumbnails[active].classList.add('active');
    } else {
      thumbnails[active].classList.remove('active');
      if(active > 0) {
        active--;
      } else {
        active = total-1;
      }
      this.productPictureThumbnailsTarget.setAttribute("data-active", active);
      thumbnails[active].classList.add('active');
    }

    let iframe = this.productPictureWrapperTarget.querySelector("iframe");
    if(iframe) {
      iframe.remove();
    }
    let video = this.productPictureWrapperTarget.querySelector("video");
    if(video) {
      video.remove();
    }

    this.productVideoWrapperTarget.style.display = "block"
    this.productVideoWrapperTarget.style.justifyContent = "unset"

    if(thumbnails[active].dataset.videoUrl || thumbnails[active].dataset.video) {
      this.productPictureTarget.classList.add('is-hidden');

      let div = this.productPictureTarget.parentNode;
      if(thumbnails[active].dataset.videoUrl) {
        let iframe = document.createElement('iframe');
        iframe.src = thumbnails[active].dataset.videoUrl;
        iframe.title = thumbnails[active].dataset.name;
        iframe.frameborder = "0"
        iframe.style.margin = "auto"
        iframe.style.width = "100%"
        iframe.style.height = "60vh";
        this.productPictureDescriptionTarget.textContent = thumbnails[active].alt;
        div.appendChild(iframe);
      } else {
        let video = document.createElement('video');
        video.controls = true;
        video.setAttribute('controlsList', 'nodownload')
        video.src = thumbnails[active].dataset.video;
        this.productPictureDescriptionTarget.textContent = thumbnails[active].alt;

        this.productVideoWrapperTarget.style.display = "flex"
        this.productVideoWrapperTarget.style.justifyContent = "center"
        div.appendChild(video);
      }
    } else {
      this.productPictureTarget.classList.remove('is-hidden');
      this.imageTarget.removeAttribute('data-video-url');

      this.productPictureTarget.src = thumbnails[active].dataset.image;
      this.productPictureDescriptionTarget.textContent = thumbnails[active].alt;
    }
  }

  previewNavigation() {
    if(event.detail > 1) {
      event.preventDefault();
    }

    if(event.target.dataset.direction) {
      if(event.target.dataset.direction == 'left') {
        this.previewImageNavigation("right")
      } else {
        this.previewImageNavigation("left")
      }
    }
    if(document.selection && document.selection.empty) {
        document.selection.empty();
    } else if(window.getSelection) {
        var sel = window.getSelection();
        sel.removeAllRanges();
    }
  }

  previewImageNavigation(direction) {
    let total = parseInt(this.productPictureThumbnailsTarget.dataset.thumbnails);
    let active = parseInt(this.productPictureThumbnailsTarget.dataset.active);
    let thumbnails = this.productPictureThumbnailsTarget.querySelectorAll('img');
    let bullets = this.bulletsTarget.querySelectorAll("div");

    thumbnails[active].classList.remove('active');
    bullets[active].querySelector('svg').setAttribute("data-prefix", 'far');

    if(direction == 'left') {
      if(active < total-1) {
        active++;
      } else {
        active = 0;
      }
    } else {
      if(active > 0) {
        active--;
      } else {
        active = total-1;
      }
    }

    this.productVideoWrapperTarget.style.display = "block"
    this.productVideoWrapperTarget.style.justifyContent = "unset"

    if(thumbnails[active].dataset.videoUrl || thumbnails[active].dataset.video) {
      this.productPictureTarget.classList.add('is-hidden');
      if(thumbnails[active].dataset.videoUrl) {
        this.imageTarget.setAttribute('data-video-url', thumbnails[active].dataset.videoUrl);
      } else {
        this.imageTarget.setAttribute('data-video', thumbnails[active].dataset.video);
      }
      let div = this.imageTarget.parentNode.parentNode;
      div.style.height = `${div.dataset.height}px`;

      this.image_zoom_iconTarget.innerHTML = `
        <span class="fa-stack fa-2x">
          <i class="fa-solid fa-circle fa-stack-2x"></i>
          <i class="fa-solid fa-play fa-stack-1x fa-inverse"></i>
        </span>
      `
    } else {
      let iframe = this.productPictureWrapperTarget.querySelector("iframe");
      if(iframe) {
        iframe.remove();
      }
      let video = this.productPictureWrapperTarget.querySelector("video");
      if(video) {
        video.remove();
      }
      this.productPictureTarget.classList.remove('is-hidden');
      this.imageTarget.removeAttribute('data-video-url');

      this.image_zoom_iconTarget.innerHTML = `
        <span class="fa-stack fa-2x">
          <i class="fa-solid fa-circle fa-stack-2x"></i>
          <i class="fa-solid fa-search fa-stack-1x fa-inverse"></i>
        </span>
      `
    }

    thumbnails[active].classList.add('active');
    this.productPictureThumbnailsTarget.setAttribute("data-active", active);
    bullets[active].querySelector('svg').setAttribute("data-prefix", 'fas');

    this.imageTarget.src = bullets[active].dataset.thumbnail;
    this.imageTarget.dataset.image = bullets[active].dataset.image;
    this.imageTarget.alt = bullets[active].dataset.title;
  }

  showImage() {
    let iframe = this.productPictureWrapperTarget.querySelector("iframe");
    if(iframe) {
      iframe.remove();
    }
    let video = this.productPictureWrapperTarget.querySelector("video");
    if(video) {
      video.remove();
    }

    this.productVideoWrapperTarget.style.display = "block"
    this.productVideoWrapperTarget.style.justifyContent = "unset"

    if(event.target.dataset.videoUrl || event.target.dataset.video) {
      this.productPictureTarget.classList.add('is-hidden');

      let div = this.productPictureTarget.parentNode;
      if(event.target.dataset.videoUrl) {
        let iframe = document.createElement('iframe');
        iframe.src = event.target.dataset.videoUrl;
        iframe.title = event.target.dataset.name;
        iframe.frameborder = "0"
        iframe.style.margin = "auto"
        iframe.style.width = "100%"
        iframe.style.height = "60vh";
        div.appendChild(iframe);
      } else {
        let video = document.createElement('video');
        video.controls = true;
        video.setAttribute('controlsList', 'nodownload')
        video.src = event.target.dataset.video;
        video.style.maxHeight = "100%";

        this.productVideoWrapperTarget.style.display = "flex"
        this.productVideoWrapperTarget.style.justifyContent = "center"
        div.appendChild(video);
      }
    } else {
      this.productPictureTarget.classList.remove('is-hidden');
    }
    this.productPictureTarget.src = event.target.dataset.image;
    this.productPictureDescriptionTarget.innerHTML = event.target.alt;
    this.productPictureWrapperTarget.classList.remove('is-hidden');
    this.productPictureWrapperTarget.focus();

    let pz = new PinchZoom(this.productPictureTarget, {
      draggableUnzoomed: false,
      minZoom: 1,
    });
    pz.enable()

    const gesture = new Gesture(this.productPictureTarget, {
      diagonalSwipes: false
    });
    gesture.on('swiperight', (event) => {
      this.pictureNavigation("right");
    });
    gesture.on('swipeleft', (event) => {
      this.pictureNavigation("left");
    });
  }

  hideImage() {
    this.productPictureWrapperTarget.classList.add('is-hidden');
    let iframe = this.productPictureWrapperTarget.querySelector("iframe");
    if(iframe) {
      iframe.remove();
    }
    let video = this.productPictureWrapperTarget.querySelector("video");
    if(video) {
      video.remove();
    }
  }

  switchImage() {
    let img = event.target;

    if(event.target.dataset.url || event.target.dataset.video) {
      this.productPictureTarget.classList.add('is-hidden');
      if(event.target.dataset.url) {
        this.imageTarget.setAttribute('data-video-url', event.target.dataset.url);
      } else {
        this.imageTarget.setAttribute('data-video', event.target.dataset.video);
      }

      let div = this.imageTarget.parentNode.parentNode;
      div.style.height = `${div.dataset.height}px`;

      this.image_zoom_iconTarget.innerHTML = `
        <span class="fa-stack fa-2x">
          <i class="fa-solid fa-circle fa-stack-2x"></i>
          <i class="fa-solid fa-play fa-stack-1x fa-inverse"></i>
        </span>
      `
    } else {
      this.productPictureTarget.classList.remove('is-hidden');
      this.imageTarget.removeAttribute('data-video-url');
      this.imageTarget.removeAttribute('data-video');

      this.image_zoom_iconTarget.innerHTML = `
        <span class="fa-stack fa-2x">
          <i class="fa-solid fa-circle fa-stack-2x"></i>
          <i class="fa-solid fa-search fa-stack-1x fa-inverse"></i>
        </span>
      `
    }

    if(this.productPictureWrapperTarget.classList.contains('is-hidden')) {
      event.target.parentNode.querySelectorAll('svg').forEach((svg, i) => {
        svg.setAttribute("data-prefix", "far")
      });

      this.imageTarget.src = img.dataset.thumbnail;
      this.imageTarget.dataset.image = img.dataset.image;
      this.imageTarget.alt = img.dataset.title;

      event.target.querySelector('svg').setAttribute("data-prefix", "fas");

      this.productPictureThumbnailsTarget.querySelectorAll("img").forEach((img, i) => {
        if(i == event.target.dataset.index) {
          img.classList.add('active');
        } else {
          img.classList.remove('active');
        }
      });

      this.productPictureThumbnailsTarget.setAttribute("data-active", event.target.dataset.index);
    } else {
      this.productPictureThumbnailsTarget.querySelectorAll("img").forEach((img, i) => {
        img.classList.remove('active');
      });

      let iframe = this.productPictureWrapperTarget.querySelector("iframe");
      if(iframe) {
        iframe.remove();
      }
      let video = this.productPictureWrapperTarget.querySelector("video");
      if(video) {
        video.remove();
      }

      this.productVideoWrapperTarget.style.display = "block"
      this.productVideoWrapperTarget.style.justifyContent = "unset"

      if(event.target.dataset.videoUrl || event.target.dataset.video) {
        this.productPictureTarget.classList.add('is-hidden');

        let div = this.productPictureTarget.parentNode;
        if(event.target.dataset.videoUrl) {
          let iframe = document.createElement('iframe');
          iframe.src = event.target.dataset.videoUrl;
          iframe.title = event.target.dataset.name;
          iframe.frameborder = "0"
          iframe.style.margin = "auto"
          iframe.style.width = "100%"
          iframe.style.height = "60vh";
          div.appendChild(iframe);
        } else {
          let videoWrapper = document.createElement('div');
          let video = document.createElement('video');
          video.controls = true;
          video.setAttribute('controlsList', 'nodownload')
          video.src = event.target.dataset.video;
          video.style.maxHeight = "100%";

          this.productVideoWrapperTarget.style.display = "flex"
          this.productVideoWrapperTarget.style.justifyContent = "center"
          div.appendChild(video);
        }
      } else {
        this.productPictureTarget.classList.remove('is-hidden');
        this.imageTarget.removeAttribute('data-video-url');

        this.productPictureTarget.src = event.target.dataset.image;
        this.productPictureDescriptionTarget.textContent = event.target.alt;
      }
      event.target.classList.add('active');
    }
  }

  switchTab() {
    this.tabsTarget.querySelectorAll("a").forEach((a, i) => {
      if(a.dataset.target == event.target.dataset.target) {
        a.parentNode.classList.add('is-active');
      } else {
        a.parentNode.classList.remove('is-active');
      }
    });

    this.tabsContentTarget.querySelectorAll("div.tab-content").forEach((div, i) => {
      if(div.id == event.target.dataset.target) {
        div.classList.remove('is-hidden');
      } else {
        div.classList.add('is-hidden');
      }
    });
  }

  toggleImageZoomIcon() {
    this.image_left_iconTarget.classList.toggle('is-hidden');
    this.image_zoom_iconTarget.classList.toggle('is-hidden');
    this.image_right_iconTarget.classList.toggle('is-hidden');
  }

  setColor() {
    let uuid = event.target.dataset.uuid;

    this.colorVariantTargets.forEach((btn, i) => {
      if(btn.dataset.uuid == uuid) {
        btn.classList.add('has-background-secondary-dark');
        this.inputUuidTarget.value = uuid;
      } else {
        btn.classList.remove('has-background-secondary-dark');
      }
    });
  }

  setSize() {
    let itemsController = this;
    let uuid = event.target.dataset.uuid;

    Rails.ajax({
      url: `/${itemsController.locale}/item/${uuid}`,
      type: "get",
      data: "",
      success: function(data) {
        if(data.description_long && data.description_long != '') {
          let div = document.querySelector("#description_long");
          div.innerHTML = data.description_long;
          div.classList.remove("is-hidden");
        } else {
          div.classList.add("is-hidden");
        }
        if(itemsController.hasPriceTarget) {
          if(data.price_with_vat) {
            if(data.price_with_discount) {
              let price_without_discount = data.price*(data.vat/100+1);
              itemsController.priceTarget.innerHTML = `
                <span class="is-size-6 has-text-weight-normal mr-3 mb-1">
                  <strike>
                    ${price_without_discount.toFixed(2)} ${I18n.t(`currencies.abbr.${itemsController.currency}`)}
                  </strike>
                </span><br>
                <span class="has-text-danger">
                  ${data.price_with_vat.toFixed(2)} ${I18n.t(`currencies.abbr.${itemsController.currency}`)}
                </span>`;
            } else {
              itemsController.priceTarget.textContent = `${data.price_with_vat.toFixed(2)} ${I18n.t(`currencies.abbr.${itemsController.currency}`)}`;
            }
            itemsController.spanPriceWithVatTarget.classList.remove('is-hidden');
          } else {
            itemsController.priceTarget.textContent = '';
            itemsController.spanPriceWithVatTarget.classList.add('is-hidden');
          }
        }
        if(data.with_stock) {
          let min = parseInt(itemsController.itemStockTarget.dataset.threshold);
          if(!min) {
            min = 0;
          }
          itemsController.itemStockTarget.classList.remove('has-text-success-dark', 'has-text-warning-dark', 'has-text-danger-dark');
          if(data.stock > min) {
            itemsController.itemStockTarget.classList.add('has-text-success-dark');
            itemsController.itemStockTarget.innerHTML = `<i class="fa-solid fa-circle-check"></i> ${I18n.t('item.in_stock')}`
            itemsController.buttonAddToCartTarget.disabled = false;
          } else if(data.stock <= min && data.stock > 0) {
            itemsController.itemStockTarget.classList.add('has-text-warning-dark');
            itemsController.itemStockTarget.innerHTML = `<i class="fa-solid fa-circle-check"></i> ${I18n.t('item.few_in_stock')}`
            itemsController.buttonAddToCartTarget.disabled = false;
          } else {
            itemsController.itemStockTarget.classList.add('has-text-danger-dark');
            itemsController.itemStockTarget.innerHTML = `<i class="fa-solid fa-circle-xmark"></i> ${I18n.t('item.currently_not_available')}`
            itemsController.buttonAddToCartTarget.disabled = true;
          }
          itemsController.itemStockTarget.classList.remove('is-hidden');
        } else {
          itemsController.itemStockTarget.classList.add('is-hidden');
        }
      },
      error: function(data) {}
    })

    this.sizeVariantTargets.forEach((btn, i) => {
      if(btn.dataset.uuid == uuid) {
        btn.classList.add('has-background-secondary-dark');
        this.buttonAddToCartTarget.setAttribute("data-uuid", uuid);
      } else {
        btn.classList.remove('has-background-secondary-dark');
      }
    });
  }

  checkQuantities() {
    if(this.unitStepsValue) {
      try {
        let d = Math.ceil(event.target.value / parseFloat(this.unitStepsValue));
        event.target.value = d * parseFloat(this.unitStepsValue);
      } catch {

      }
    }
  }
}
