import { Controller } from "stimulus"

export default class extends Controller {

  static targets = [ "email", "phone" ]

  initialize() {

  }

  connect() {
    window.onSubmit = function(token) {
      let form = document.getElementById("contact-form");
      Rails.fire(form, 'submit');
    }
  }

  checkContactForm() {
    if(this.validateEmail(this.emailTarget.value)) {
      this.phoneTarget.required = "";
    } else {
      this.phoneTarget.required = true;
    }
    if(this.phoneTarget.value.length > 4) {
      this.emailTarget.required = "";
    } else {
      this.emailTarget.required = true;
    }
  }

  validateEmail(mail) {
    if (/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(mail))
    {
      return (true)
    }
    return (false)
  }
}
