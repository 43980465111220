var modal = exports;
var $el;

function eventListeners($el) {
  // Add a click event on various child elements to close the parent modal
  ($el.querySelectorAll('.modal-background, .modal-close, .modal-card-head .delete, .modal-card-foot .button') || []).forEach(($close) => {
    const $target = $close.closest('.modal');

    $close.addEventListener('click', (event) => {
			if(event.target.classList.contains('event-success')) {
				onSuccess();
			} else {
				onCancel();
			}
      modal.closeModal($target);
    });
  });

  // Add a keyboard event to close all modals
  document.addEventListener('keydown', (event) => {
    const e = event || window.event;

    if (e.keyCode === 27) { // Escape key
      modal.closeAllModals();
    }
  });
}

function onSuccess() {
  data = {};
  let section = document.querySelector(".modal-card-body");
  section.querySelectorAll("input").forEach((inp, i) => {
    data[inp.id] = inp.value
  });

  const eventSuccess = new CustomEvent('success', {
    detail: data
  });
	$el.dispatchEvent(eventSuccess);
}

function onCancel() {
  const eventCancel = new Event('cancel');
	$el.dispatchEvent(eventCancel);
}

modal.createModal = function(title, content, buttons = [{label: I18n.t('btn.close'), event: 'close', classes: ''}]) {
	let html = `
		<div class="modal-background"></div>
			<div class="modal-card">
	`
	if(title != '') {
		html += `
			<header class="modal-card-head has-background-white">
				<p class="modal-card-title is-size-5">${title}</p>
				<button class="delete" aria-label="close"></button>
			</header>
		`
	}
	html += `
			<section class="modal-card-body">
				${content}
			</section>
			<footer class="modal-card-foot has-background-white">
    `
  buttons.forEach((btn, i) => {
    html += `<button class="button ${btn.classes} event-${btn.event}">${btn.label}</button>`
  });

  html += `
			</footer>
		</div>
	`

	let body = document.querySelector('body');
	let modal = document.createElement('div');
	modal.classList.add("modal");
	modal.innerHTML = html;
	$el = body.appendChild(modal);

	return this;
}

modal.openModal = function() {
	$el.classList.add('is-active');
	eventListeners($el);

	return $el;
}

modal.closeModal = function($el) {
	$el.classList.remove('is-active');
	$el.remove();
}

modal.closeAllModals = function () {
	(document.querySelectorAll('.modal') || []).forEach(($modal) => {
		modal.closeModal($modal);
	});
}

modal.addEventListener = function(event, callback) {
	$el.addEventListener(event, callback)
}
